import React, { useState, useRef, useEffect } from "react";
import "./home.css";
import Lists from "../services/apiService";
import Svg from "../components/svg";
import Card from "./card";
import Loader from "./loader";
import Content from "./content";
import Carousel from "./swiper-smallScreen";
import useWindowSize from "../services/windowSizeService";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

function Home() {
  const [num, setNum] = useState(0);
  const [contentAnimated, setContentAnimated] = useState(false);
  const sliderRef = useRef(0);
  const [lists, setLists] = useState([]);

  const width = useWindowSize();

  gsap.registerPlugin(useGSAP);

  useEffect(() => {
    const fetchLists = async () => {
      const data = await Lists();
      setLists(data);
    };

    fetchLists();
  }, []);

  const handleSlide = (direction) => {
    if (sliderRef.current) {
      const items = sliderRef.current.querySelectorAll(".item");
      if (direction === "next" && items.length > 0) {
        sliderRef.current.append(items[0]);
        setNum((prevNum) => prevNum + 1);
      } else if (direction === "prev" && items.length > 0) {
        sliderRef.current.prepend(items[items.length - 1]);
        setNum((prevNum) => prevNum - 1);
      }
    }

    setContentAnimated(true);
    setTimeout(() => {
      setContentAnimated(false);
    }, 1000); // Reset animation after 1 second
  };

  useGSAP(() => {
    if (width > 1060) {
      const animation = gsap.timeline({
        defaults: { duration: 1, ease: "back" },
      });

      animation
        .fromTo(
          "#svgImg",
          { scale: 2 },
          {
            scale: 1,
            transformOrigin: "center center",
          }
        )

        .fromTo(
          ".project_display",
          { x: "150%" },
          {
            x: 0,
            ease: "bounce",
          },
          "<0.5"
        )

        .fromTo(
          ".m-wrapper",
          { y: "250%" },
          {
            y: 0,
          },
          "<0.5"
        )

        .fromTo(".header", { y: -150 }, { y: 0 }, "<")

        .fromTo(
          ".content",
          { scale: 0 },
          {
            scale: 1,
            transformOrigin: "center center",
          },
          "<0.5"
        );
    } else {
      console.log("Skipping animations for mobile");
    }
  }, [width]);

  if (lists.length === 0) {
    return <Loader />;
  } else {
    return (
      <div className="uiy">
        {width <= 1060 ? (
          <Carousel
            clickedNext={() => {
              if (num < lists.length - 1) {
                setNum(num + 1);
              } else {
                setNum(0);
              }
            }}
            clickedPrev={() => {
              if (num > 0) {
                setNum(num - 1);
              } else {
                setNum(lists.length - 1);
              }
            }}
          />
        ) : (
          <Svg present_number={num} siteLink={lists.siteLink} />
        )}
        {width > 1060 && (
          <div className="covering">
            <div className="project_display">
              <ul ref={sliderRef}>
                <Card />
              </ul>

              <div className="btn-layout">
                <button
                  className="btn next"
                  onClick={() => handleSlide("next")}
                  disabled={num === lists.length - 1}
                >
                  {width <= 1500 ? (
                    <svg
                      width="15"
                      height="15"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                        stroke-linejoin="round"
                        stroke-linecap="round"
                      ></path>
                    </svg>
                  ) : (
                    <>
                      {`Next`}{" "}
                      <svg
                        width="15"
                        height="15"
                        stroke="currentColor"
                        stroke-width="1.5"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                          stroke-linejoin="round"
                          stroke-linecap="round"
                        ></path>
                      </svg>
                    </>
                  )}
                </button>
                <button
                  className="btn prev"
                  onClick={() => handleSlide("prev")}
                  disabled={num <= 0}
                >
                  {width <= 1500 ? (
                    <svg
                      width="15"
                      height="15"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5 4.5L3 12m0 0l7.5 7.5M3 12H21"
                        stroke-linejoin="round"
                        stroke-linecap="round"
                      ></path>
                    </svg>
                  ) : (
                    <>
                      <svg
                        width="15"
                        height="15"
                        stroke="currentColor"
                        stroke-width="1.5"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.5 4.5L3 12m0 0l7.5 7.5M3 12H21"
                          stroke-linejoin="round"
                          stroke-linecap="round"
                        ></path>
                      </svg>
                      {`Prev`}{" "}
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        )}

        <Content currentNum={num} animationActive={contentAnimated} />
      </div>
    );
  }
}

export default Home;
