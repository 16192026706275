import React from "react";
import ImageFrame from "./animated-frame";
import MSlider from "./slider-manual";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/all";

import "./about.css";

gsap.registerPlugin(useGSAP, ScrollTrigger, TextPlugin);

function About() {
  useGSAP(() => {
    const sections = gsap.utils.toArray(".panel");

    const scrollTween = gsap.to(sections, {
      xPercent: -100 * (sections.length - 1),
      ease: "none",

      scrollTrigger: {
        trigger: ".s_container",
        start: "top top",
        pin: true,
        scrub: 0.1,
        // snap: directionalSnap(1 / (sections.length - 1)),
        end: "+=3000",
      },
    });

    // gsap.set(".box-1, .box-2", { y: 100 });

    ScrollTrigger.defaults({
      markers: false,
      //  { startColor: "white", endColor: "red" },
    });

    // red section

    const year2 = gsap.timeline();

    year2
      .fromTo(
        ".box-1",
        { opacity: 0, x: 500 },
        {
          opacity: 1,
          x: 0,
          rotate: 360,
          duration: 1,
          stagger: 0.5,
          ease: "back",
          scrollTrigger: {
            trigger: ".red div",
            containerAnimation: scrollTween,
            start: "left center",
            end: "+=500",
            scrub: true,
            toggleActions: "play none reverse reset",
            id: "1",
          },
        }
      )

      .fromTo(
        ".y2",
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          scrollTrigger: {
            trigger: ".red div",
            containerAnimation: scrollTween,
            start: "left center",
            end: "+=250",
            scrub: true,
            toggleActions: "play none reverse reset",
            id: "1",
          },
        }
      );

    // gray section

    const year3 = gsap.timeline();

    year3
      .fromTo(
        ".box-2",
        { y: 300, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          ease: "bounce",
          stagger: 0.5,
          scrollTrigger: {
            trigger: ".box-2",
            containerAnimation: scrollTween,
            start: "center 80%",
            end: "+=500",
            scrub: true,
            toggleActions: "play none none reset",
            id: "2",
          },
        }
      )

      .fromTo(
        ".y3",
        { opacity: 0, y: -50 },
        {
          opacity: 1,
          y: 0,
          ease: "power1.in",
          scrollTrigger: {
            trigger: ".gray div",
            containerAnimation: scrollTween,
            start: "left center",
            end: "+=50",
            scrub: true,
            toggleActions: "play none reverse reset",
            id: "2",
          },
        }
      );

    // purple section

    const year4 = gsap.timeline();

    // ScrollTrigger.create({
    //   trigger: ".box-3",
    //   containerAnimation: scrollTween,
    //   toggleClass: "active",
    //   start: "center 60%",
    //   id: "3",
    // });

    year4
      .fromTo(
        ".box-3",
        { opacity: 0, x: 100 },
        {
          x: 0,
          opacity: 1,
          ease: "elastic",
          stagger: 0.5,
          scrollTrigger: {
            trigger: ".box-3",
            containerAnimation: scrollTween,
            start: "left 50%",
            end: "+=300",
            scrub: true,
            toggleActions: "play none none reset",
            id: "3",
          },
        }
      )

      .fromTo(
        ".y4",
        { opacity: 0, x: 100 },
        {
          opacity: 1,
          x: 0,
          ease: "elastic",
          scrollTrigger: {
            trigger: ".purple div",
            containerAnimation: scrollTween,
            start: "left 60%",
            end: "+=100",
            scrub: true,
            toggleActions: "play none reverse reset",
            id: "3",
          },
        }
      );

    // orange section

    const year5 = gsap.timeline();

    year5
      .fromTo(
        ".box-4",
        { opacity: 0, y: 500 },
        {
          opacity: 1,
          y: 0,
          rotate: 360,
          duration: 1,
          stagger: 0.5,
          ease: "bounce",
          scrollTrigger: {
            trigger: ".orange div",
            containerAnimation: scrollTween,
            start: "left center",
            end: "+=500",
            scrub: true,
            toggleActions: "play none reverse reset",
            id: "4",
          },
        }
      )

      .fromTo(
        ".y5",
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          scrollTrigger: {
            trigger: ".orange div",
            containerAnimation: scrollTween,
            start: "left 60%",
            end: "+=250",
            scrub: true,
            toggleActions: "play none reverse reset",
            id: "4",
          },
        }
      );

    // green section
    ScrollTrigger.create({
      trigger: ".green",
      containerAnimation: scrollTween,
      start: "center 65%",
      end: "center 51%",
      onEnter: () => console.log("enter"),
      onLeave: () => console.log("leave"),
      onEnterBack: () => console.log("enterBack"),
      onLeaveBack: () => console.log("leaveBack"),
      onToggle: (self) => console.log("active", self.isActive),
      id: "5",
    });

    // Work Expereince Section
    gsap.to(".s_container div", {
      top: "8%",
      ease: "bounce",

      scrollTrigger: {
        trigger: ".s_container div",
        start: "top 20%",
        scrub: true,
      },
    });

    gsap.to(".we", {
      text: "2016 - 1018",
      ease: "bounce",

      scrollTrigger: {
        trigger: ".red div",
        start: "top 20%",
        end: "+=100",
        onEnter: () => gsap.set(".we", { text: "2016 - 2018" }),
        onEnterBack: () => gsap.set(".we", { text: "Timeline" }),
        // scrub: true,
      },
    });

    gsap.to(".we", {
      text: "2018 - 2020",
      ease: "bounce",

      scrollTrigger: {
        trigger: ".box-2",
        containerAnimation: scrollTween,
        start: "center 60%",
        end: "+=100",
        onEnter: () => gsap.set(".we", { text: "2018 - 2020" }),
        onEnterBack: () => gsap.set(".we", { text: "2016 - 2018" }),
        // scrub: true,
      },
    });

    gsap.to(".we", {
      text: "2020 - Now",
      ease: "bounce",

      scrollTrigger: {
        trigger: ".box-4",
        containerAnimation: scrollTween,
        start: "center 80%",
        end: "+=100",
        onEnter: () => gsap.set(".we", { text: "2002 - Now" }),
        onEnterBack: () => gsap.set(".we", { text: "2018 - 2020" }),
        // scrub: true,
      },
    });

    gsap.to("code", { fontSize: "0.5em" });

    gsap.to("code", {
      text: {
        value: "Short Not 😜",
        delimiter: " ",
      },
      scrollTrigger: {
        trigger: "code",
        scrub: true,
        start: "top 15%",
      },
    });

    // only show the relevant section's markers at any given time
    gsap.set(
      ".gsap-marker-start, .gsap-marker-end, .gsap-marker-scroller-start, .gsap-marker-scroller-end",
      { autoAlpha: 0 }
    );
    ["red", "gray", "purple", "orange", "green"].forEach((triggerClass, i) => {
      ScrollTrigger.create({
        trigger: "." + triggerClass,
        containerAnimation: scrollTween,
        start: "left 30%",
        end: i === 3 ? "right right" : "right 30%",
        markers: false,
        onToggle: (self) =>
          gsap.to(".marker-" + (i + 1), {
            duration: 0.25,
            autoAlpha: self.isActive ? 1 : 0,
          }),
      });
    });

    // helper function for causing the sections to always snap in the direction of the scroll (next section) rather than whichever section is "closest" when scrolling stops.
    // function directionalSnap(increment) {
    //   const snapFunc = gsap.utils.snap(increment);

    //   return (raw, self) => {
    //     const snappedValue = snapFunc(raw);
    //     const difference = Math.abs(snappedValue - raw);

    //     // Snap to the closest value based on scroll direction
    //     if (difference < 1e-4 || (snappedValue < raw) === (self.direction < 0)) {
    //       return snappedValue;
    //     } else {
    //       return self.direction < 0 ? snappedValue - increment : snappedValue + increment;
    //     }
    //   }
    // }
  });

  return (
    <div style={{ height: "100vh" }}>
      <div className="container" style={{ overflow: " hidden" }}>
        <div className="descrip row">
          <div className="col-lg-8 col-12">
            <h1 className="heading-left">About Me</h1>
            <p
              style={{
                textAlign: "justify",
                maxWidth: "70ch",
              }}
            >
              Imagine growing up in a small, humble town in Nigeria—a place
              where, from a young age, you learn that nothing comes without hard
              work.{" "}
              <span className="highlighted_text">
                In this town, grit is a given, and ambition is nurtured by the
                daily struggles around you.
              </span>
              Hard work wasn’t just an expectation—it was like a sibling, always
              by your side. But as the world grew more competitive, I realized
              hard work alone wouldn’t be enough.
              <span className="highlighted_text">
                {" "}
                So, I learned how to work smart.
              </span>
            </p>

            <p
              style={{
                textAlign: "justify",
                maxWidth: "70ch",
              }}
            >
              For as long as I can remember, I've been grinding—pushing
              boundaries. But it wasn’t until 2018 that I fully plunged into the
              world of design.
              <span className="highlighted_text">
                Growing up, I saw firsthand how great design could solve
                everyday problems, and even transform lives, while poor design
                could have devastating consequences.
              </span>
              These realizations ignited a passion within me that never faded.
              My true "aha" moment came during my university years when I
              stumbled upon the world of digital design in tech.
              <span className="highlighted_text">
                From that moment, there was no turning back.
              </span>
            </p>

            <p
              className="p"
              style={{
                textAlign: "justify",
                maxWidth: "60ch",
              }}
            >
              I'm{" "}
              <span
                className="text-uppercase fw-bold"
                style={{ color: "var(--code-color)" }}
              >
                Solomon Oni
              </span>
              , a user expereince, user interface and frontend development
              creative. Currently, I’m pursuing my development as a Product
              Designer, with a minor in Entrepreneurship and Innovation, at the
              University of Sussex(England). By day, I lead UX design and
              Frontend development at Byteworks, where I’ve been shaping
              interactive experiences for brands.
            </p>
          </div>

          <div className="col-lg-4 col-auto">
            <ImageFrame />
          </div>
        </div>

        <div className="s_container">
          <div
            className="we_cover"
            data-animation="fade-in"
            style={{ position: "absolute", margin: "5rem 1rem 0 1rem" }}
          >
            <h1 className="work_exp_h1">Work Experience</h1>
            <p className="we">Timeline</p>
          </div>

          <section className="panel blue" data-animation="fade-in">
            <div className="panel_div">
              <pre>Year: 2016</pre>
              <h3 style={{ color: "var(--code-color)" }}>
                KISMAT Nursery & Primary School - Substitute Art Teacher
              </h3>
              <p>
                Tried to give back to the younger generation by sharing
                knowledge and guidance at KISMAT. I enhanced learning for a
                Primary 5 class of 15 pupils using practical and audio/visual
                aids. Collaborating with fellow teachers, we transformed the
                classroom into a vibrant space where learning was engaging and
                memorable.
              </p>
            </div>
            <div
              className="panel_div row"
              style={{
                // marginTop: "20px",
                textAlign: "center",
                backgroundColor: "transparent",
                width: "100%",
              }}
            >
              <div className="col-4">
                <img
                  src="./images/project/about-images/kismat/kismat-1.jpg"
                  alt=""
                  style={{ width: "80%" }}
                />
              </div>
              <div className="col-4">
                <img
                  src="./images/project/about-images/kismat/kismat-2.jpg"
                  alt=""
                  style={{ width: "80%" }}
                />
              </div>
              <div className="col-4">
                <img
                  src="./images/project/about-images/kismat/kismat-3.jpg"
                  alt=""
                  style={{ width: "80%" }}
                />
              </div>
            </div>
          </section>

          <section className="panel red">
            <div className="panel_div y2">
              <pre>Year: 2017 - 2018</pre>
              <h3 style={{ color: "var(--code-color)" }}>
                Pillars Construct - Design Intern
              </h3>
              <p>
                Later that year, I found a path that merged creativity and
                technology to solve real-world problems. As an Intern Designer
                for Pillars Construct, I collaborated with engineers to create
                user-friendly interfaces for instructional guides using Adobe
                Illustrator, Adobe XD, and Photoshop. This experience enhanced
                my ability to convey complex information through intuitive
                design.
              </p>
            </div>
            <div
              className="panel_div row"
              style={{
                // marginTop: "20px",
                textAlign: "center",
                backgroundColor: "transparent",
                width: "100%",
              }}
            >
              <div className="col-4 box-1">
                <img
                  src="./images/project/about-images/pillars/pillars-1.jpg"
                  alt=""
                  style={{ width: "80%" }}
                />
              </div>
              <div className="col-4 box-1">
                <img
                  src="./images/project/about-images/pillars/pillars-2.jpg"
                  alt=""
                  style={{ width: "80%" }}
                />
              </div>
              <div className="col-4 box-1">
                <img
                  src="./images/project/about-images/pillars/pillars-3.jpg"
                  alt=""
                  style={{ width: "80%" }}
                />
              </div>
            </div>
          </section>

          <section className="panel gray">
            <div className="panel_div y3">
              <pre>Year: 2018 -2020</pre>
              <h3 style={{ color: "var(--code-color)" }}>
                9ijakids - UI Designer
              </h3>
              <p>
                I began my design journey at 9ijakids, working with teams to
                create interactive educational games for children. I helped
                shape the design strategy, developed reusable templates that cut
                development time by 30%, and worked on a Google-partnered
                project creating animated children's stories, blending
                creativity with education.
              </p>
            </div>
            <div
              className="panel_div row"
              style={{
                // marginTop: "20px",
                textAlign: "center",
                backgroundColor: "transparent",
                width: "100%",
              }}
            >
              <div className="col-4 box-2">
                <img
                  src="./images/project/about-images/9ijakids/9ijakids-1.jpg"
                  alt=""
                  style={{ width: "80%" }}
                />
              </div>
              <div className="col-4 box-2">
                <img
                  src="./images/project/about-images/9ijakids/9ijakids-2.jpg"
                  alt=""
                  style={{ width: "80%" }}
                />
              </div>
              <div className="col-4 box-2">
                <img
                  src="./images/project/about-images/9ijakids/9ijakids-3.jpg"
                  alt=""
                  style={{ width: "80%" }}
                />
              </div>
            </div>
          </section>

          <section className="panel purple">
            <div className="panel_div y4">
              <pre>Year: 2020 - 2021</pre>
              <h3 style={{ color: "var(--code-color)" }}>
                Lorem Excellentiam Services - Senior Instructional, UX/UI
                Designer
              </h3>
              <p>
                At Lorem Excellentiam, I led the design of e-learning platforms,
                reducing call center training times by 40% with user-centered
                solutions. I introduced reusable templates that cut course
                development time by 30% and created interactive games, impacting
                corporations and developing animated stories for Google Bolo,
                enhancing learning across Africa.
              </p>
            </div>
            <div
              className="panel_div row"
              style={{
                // marginTop: "20px",
                textAlign: "center",
                backgroundColor: "transparent",
                width: "100%",
              }}
            >
              <div className="col-4 box-3">
                <img
                  src="./images/project/about-images/lorem/lorem-1.jpg"
                  alt=""
                  style={{ width: "80%" }}
                />
              </div>
              <div className="col-4 box-3">
                <img
                  src="./images/project/about-images/lorem/lorem-2.jpg"
                  alt=""
                  style={{ width: "80%" }}
                />
              </div>
              <div className="col-4 box-3">
                <img
                  src="./images/project/about-images/lorem/lorem-3.jpg"
                  alt=""
                  style={{ width: "80%" }}
                />
              </div>
            </div>
          </section>

          <section className="panel orange">
            <div className="panel_div y5">
              <pre>Year: 2021 - 2022</pre>
              <h3 style={{ color: "var(--code-color)" }}>
                Byteworks Technology Solutions LTD - UX Developer
              </h3>
              <p>
                As my career advanced, I shifted to frontend development,
                combining creativity with functionality. At Byteworks, I led the
                redesign of the State Internal Revenue Service’s system,
                reducing abandonment rates by 50% and increasing engagement. I
                implemented usability testing, improved designs, optimized SEO,
                and mentored junior developers, turning complex systems into
                user-friendly interfaces.
              </p>
            </div>
            <div
              className="panel_div row"
              style={{
                // marginTop: "20px",
                textAlign: "center",
                backgroundColor: "transparent",
                width: "100%",
              }}
            >
              <div className="col-6 box-4">
                <img
                  src="./images/project/about-images/byteworks/byte-1.jpg"
                  alt=""
                  style={{ width: "55%" }}
                />
              </div>
              <div className="col-6 box-4">
                <img
                  src="./images/project/about-images/byteworks/byte-2.jpg"
                  alt=""
                  style={{ width: "55%" }}
                />
              </div>
              {/* <div className="col-4 box-4">
                <img
                  src="./images/project/about-images/byteworks/byte-3.jpg"
                  alt=""
                  style={{ width: "80%" }}
                />
              </div> */}
            </div>
          </section>

          <section className="panel green">
            <div className="panel_div y6">
              <pre>Year: 2022 - Now</pre>
              <h3 style={{ color: "var(--code-color)" }}>
                Byteworks Technology Solutions LTD - Lead Product Designer |
                Frontend Developer
              </h3>
              <p>
                In this role, I combined my product design and front-end
                development skills to deliver intuitive digital experiences. As
                Lead Product Designer and Frontend Developer, I took projects
                from concept to deployment, creating visually appealing and
                user-friendly web and mobile applications. My focus on reusable
                code saved the company over £60,000, and using Agile methodology
                increased user engagement by over 10%.
              </p>
            </div>
            <div
              className="panel_div row"
              style={{
                // marginTop: "20px",
                textAlign: "center",
                backgroundColor: "transparent",
                width: "100%",
              }}
            >
              <div className="col-4 box-5">
                <img
                  src="./images/project/about-images/byteworks/byte-3.jpg"
                  alt=""
                  style={{ width: "80%" }}
                />
              </div>
              <div className="col-4 box-5">
                <img
                  src="./images/project/about-images/byteworks/byte-4.jpg"
                  alt=""
                  style={{ width: "80%" }}
                />
              </div>
              <div className="col-4 box-5">
                <img
                  src="./images/project/about-images/byteworks/byte-5.jpg"
                  alt=""
                  style={{ width: "80%" }}
                />
              </div>
            </div>
          </section>
        </div>
      </div>

      <section className="design_goal final">
        <div className="">
          <div style={{ margin: "-5.3rem auto" }}>
            <div className="heading-left">
              <h3
                style={{
                  fontSize: "clamp(3rem, 10vw, 6rem)",
                  padding: "1rem",
                  fontWeight: "600",
                  textAlign: "center",
                }}
                data-animation="fade-in"
              >
                My Freetime Activities
              </h3>
              {/* <div className="heading-underline-left "></div> */}
            </div>
          </div>
          <div style={{ margin: "4rem 0" }} data-animation="fade-in">
            <div id="circle_wrapper">
              <MSlider />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
