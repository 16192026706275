import React, { useState, useEffect } from "react";
import Lists from "../services/apiService";
import Loader from "./loader";

function Card() {

  const [lists, setLists] = useState([]); // Start with an empty array

   useEffect(() => {
     const fetchLists = async () => {
       const data = await Lists();
       setLists(data);
     };

     fetchLists();
   }, []);
   
   if (lists.length === 0){
     <Loader />;
   } else{
  return (
    <>
      {lists.map((list, index) => (
        <li
          key={index}
          className="item"
          style={{ backgroundImage: `url(${list.img})` }}
        >
          <div className="card_display_content">
            <h2 className="title text-uppercase text-light">{list.name}</h2>
            <p className=" text-light">{list.year}</p>
          </div>
        </li>
      ))}
    </>
  );
   }


}

export default Card;
