import axios from "axios";

const Lists = async () => {
  const url = process.env.REACT_APP_API_URL;
  

  try {
    const response = await axios.get(`${url}/projects`);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

export default Lists;
