import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./image-carousel.css";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

function ImageCarousel(props) {
  const [currentImg, setCurrentImg] = useState([]);

  useEffect(() => {
    const createImages = () => {
      const images = props.images;
      setCurrentImg(images.map((image) => image.img));
    };

    createImages();
  }, [props.images]);

  return (
    <>
      <Swiper
        style={{ margin: "5rem auto" }}
        slidesPerView={3}
        spaceBetween={30}
        centeredSlides={true}
        loop={true}
        pagination={{
          type: "",
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
        data-animation="fade-in"
      >
        {currentImg.map((cImg, index) => {
          return (
            <SwiperSlide key={index}>
              <img
                data-animation="fade-in"
                src={cImg}
                style={{ objectFit: "contain" }}
                loading="lazy"
                alt="sliding images"
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}

export default ImageCarousel;
