// import ThemeSwitchService from "../services/themeSwitchService";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
import "./multipurpose-kit.css";

function Multipurpose(props) {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const location = useLocation().pathname;

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode); // Toggle local state
    props.darkModeOn(); // Call the function passed from parent
  };

  // Function to scroll to a specific section
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div
      className="m-wrapper"
      style={{
        position: location !== "/home" && location !== "/contact" && "fixed",
      }}
    >
      <input className="hidden-trigger" id="toogle" type="checkbox" />
      <label className="ircle" for="toogle">
        <svg
          viewBox="0 0 48 48"
          version="1.1"
          height="48"
          width="48"
          href="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg"
          className="svg"
        >
          <Icon
            icon="line-md:plus"
            width="48"
            height="48"
            style={{ color: "var(--white)" }}
          />
        </svg>
      </label>

      <div className="subs">
        <button className="sub-circle">
          <input
            onChange={toggleDarkMode}
            className="hidden-sub-trigger "
            id="sub1"
            type="checkbox"
            name="sub-circle"
            value="1"
          />
          <label for="sub1">
            {isDarkMode ? (
              <Icon
                icon="line-md:sunny-filled-loop-to-moon-filled-loop-transition"
                width="2em"
                height="2em"
                style={{ color: "#523C72" }}
              />
            ) : (
              <Icon
                icon="line-md:moon-filled-alt-to-sunny-filled-loop-transition"
                width="2em"
                height="2em"
                style={{ color: "#fcbc05" }}
              />
            )}
          </label>
        </button>
        <button className="sub-circle">
          <input
            className="hidden-sub-trigger"
            id="sub2"
            type="radio"
            name="sub-circle"
            value="1"
          />
          <label for="sub2">
            <Link to="/home">
              <Icon
                icon="line-md:home-simple-filled"
                width="1.2em"
                height="1.2em"
                style={{ color: isDarkMode ? "#523C72" : "#fcbc05" }}
              />
            </Link>
          </label>
        </button>
        <button className="sub-circle" onClick={scrollToTop}>
          <input
            className="hidden-sub-trigger"
            id="sub3"
            type="radio"
            name="sub-circle"
            value="1"
          />
          <label for="sub3">
            <Icon
              icon="line-md:chevron-up"
              width="1.2em"
              height="1.2em"
              style={{ color: isDarkMode ? "#523C72" : "#fcbc05" }}
            />
          </label>
        </button>
      </div>
    </div>
  );
}

export default Multipurpose;
