import React from "react";

function Footer() {
  const currentDate = new Date().getFullYear();

  return (
    <footer className="container">
      <div className="footer">
        <div className="copyright">
          &copy; <span>Solomon Oni</span> <span> {currentDate}</span>
        </div>
        <div>
          <span>Designed & developed by me</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
