import "./animated-frame.css";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { TextPlugin } from "gsap/all";

gsap.registerPlugin(useGSAP, TextPlugin);

function ImageFrame() {
  const tl = gsap.timeline({ ease: "linear" });

  function pause() {
    return tl.pause();
  }

  function play() {
    return tl.play();
  }

  useGSAP(() => {
    tl.fromTo(
      ".wave",
      { rotate: 10 },
      { rotate: -10, repeat: -1, yoyo: true }
    ).to("figcaption", {
      text: {
        value: "Solomon Oni",
        delay: 5,
        deliminter: " ",
      },
    });
  });
  return (
    <figure className="wave" onMouseEnter={pause} onMouseLeave={play}>
      <img src="./images/project/svg/mypics.JPG" alt="rajni" />
      <figcaption>soloPayne</figcaption>
    </figure>
  );
}

export default ImageFrame;
