import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import navs from "./navs";
import useWindowSize from "../services/windowSizeService";

import MenuIcon from "@mui/icons-material/Menu";

import Avatar from "@mui/material/Avatar";

import IconButton from "@mui/material/IconButton";
import "./header.css";
import "./offcanvas.css";

function Header({ theme, darkModeOn }) {
  const [scrollY, setScrollY] = useState(0);
  const width = useWindowSize();

  const [open, setOpen] = useState(false);

  const toggleOffcanvas = () => {
    setOpen((prev) => !prev);
    console.log(open);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className={scrollY >= 100 ? "header header_wrap" : "header "}>
        {theme === "dark" ? (
          <Link to="/home">
            <img
              className="mylogo"
              src={
                width >= 1060
                  ? "/images/mylogo.svg"
                  : "/images/monogram-light.svg"
              }
              style={{ width: width >= 1060 ? "" : "clamp(4rem, 15vw, 6rem)" }}
              alt="my logo"
            />
          </Link>
        ) : (
          <Link to="/home">
            <img
              className="mylogo"
              src={
                width >= 1060
                  ? "/images/mylogo-dark.svg"
                  : "/images/monogram-dark.svg"
              }
              style={{ width: width >= 1060 ? "" : "clamp(4rem, 15vw, 6rem)" }}
              alt="my logo"
            />
          </Link>
        )}

        {width >= 1060 ? (
          <div className="nav_list">
            {navs.map((nav, index) => {
              return (
                <Link
                  to={nav.link}
                  className="nav_list_link"
                  key={index}
                  target={`${index === 3 ? "_blank" : ""}`}
                >
                  {nav.title}
                </Link>
              );
            })}
          </div>
        ) : (
          <React.Fragment>
            <IconButton onClick={toggleOffcanvas}>
              <Avatar>
                <MenuIcon />
              </Avatar>
            </IconButton>
          </React.Fragment>
        )}
      </div>
      <div
        className={`page-menu-offcanvas ${open && width < 1060 ? "open" : ""}`}
      >
        <div className="page-menu-offcanvas-body">
          {navs.map((nav, index) => {
            return (
              <h1>
                <Link
                  to={nav.link}
                  onClick={toggleOffcanvas}
                  className="nav_list_link text-uppercase"
                  key={index}
                  target={`${index === 3 ? "_blank" : ""}`}
                >
                  {index === 4 ? "More Projects" : nav.title}
                </Link>
              </h1>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Header;
