import React, { useState, useRef, useEffect } from "react";
import "./swiper-smallScreen.css";
import Lists from "../services/apiService";
import { Icon } from "@iconify/react/dist/iconify.js";

const Carousel = (props) => {
  const [sliderItems, setSliderItems] = useState([""]); // Placeholder for actual image data
  const [thumbnailItems, setThumbnailItems] = useState([""]);
  const [lists, setLists] = useState([]);
  const timeRunning = 500;
  const carouselRef = useRef(null);

  useEffect(() => {
    const fetchLists = async () => {
      const data = await Lists();
      setLists(data);
    };

    fetchLists();
  }, []);

  useEffect(() => {
    const newImages = lists.map((project) => project);
    setSliderItems(newImages);
    setThumbnailItems(newImages);
  }, [lists]);

  const showSlider = (type) => {
    if (carouselRef.current) {
      const updatedSliderItems = [...sliderItems];
      const updatedThumbnailItems = [...thumbnailItems];

      if (type === "next") {
        updatedSliderItems.push(updatedSliderItems.shift());
        updatedThumbnailItems.push(updatedThumbnailItems.shift());
        carouselRef.current.classList.add("next");
      } else {
        updatedSliderItems.unshift(updatedSliderItems.pop());
        updatedThumbnailItems.unshift(updatedThumbnailItems.pop());
        carouselRef.current.classList.add("prev");
      }

      setSliderItems(updatedSliderItems);
      setThumbnailItems(updatedThumbnailItems);

      setTimeout(() => {
        carouselRef.current.classList.remove("next");
        carouselRef.current.classList.remove("prev");
      }, timeRunning);
    }
  };

  return (
    <div className="carousel" ref={carouselRef}>
      <div className="list">
        {sliderItems.map((item, index) => (
          <div className="thumb" key={index}>
            <div className="hm-bg-wrapper">
              <img src={item.img} alt={`Slider ${index}`} />
              <div className="rect"></div>
            </div>
          </div>
        ))}
      </div>

      <div className="thumbnail">
        {thumbnailItems.map((item, index) => (
          <div className="thumb" key={index}>
            <img src={item.img} alt={`Thumbnail ${index}`} />

            <div className="content">
              <div className="title" style={{ fontSize: "inherit" }}>
                {item.name}
              </div>
              <div className="description">{item.year}</div>
            </div>
          </div>
        ))}
      </div>

      {/* Arrows */}
      <div className="arrows">
        <button
          id="prev"
          onClick={() => {
            showSlider("prev");
            props.clickedPrev();
          }}
        >
          <Icon icon="line-md:arrow-right" width="1.2em" height="1.2em" />
        </button>
        <button
          id="next"
          onClick={() => {
            showSlider("next");
            props.clickedNext();
          }}
        >
          <Icon icon="line-md:arrow-left" width="1.2em" height="1.2em" />
        </button>
      </div>
    </div>
  );
};

export default Carousel;
