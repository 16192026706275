import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { gsap } from "gsap/gsap-core";
import { TextPlugin } from "gsap/all";
import { useGSAP } from "@gsap/react";

import useWindowSize from "../services/windowSizeService";

import "./cover-page.css";

const RedirectComponent = () => {
  const width = useWindowSize();

  gsap.registerPlugin(useGSAP, TextPlugin);
  const navigate = useNavigate();

  useEffect(() => {
    const timer1 = setTimeout(() => {
      gsap.to(".main", { scale: 10, opacity: 0, ease: "slow", duration: .8 });
    }, 6000);

    // Set a timeout to redirect after 3 seconds (3000 ms)
    const timer = setTimeout(() => {
      gsap.to(".main", { scale: 10, opacity: 0 });
      navigate("/home"); // Redirect to home page
    }, 7000);

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer1, timer);
  }, [navigate]);

  var tl = gsap.timeline();

  useGSAP(() => {
    gsap.set(".main>h1, #demoh5, #demoh1, .fullname, .portfolio", {
      autoAlpha: 1,
    });

    gsap.fromTo(".unflipped", { opacity: 0 }, { opacity: 1, delay: 3 });

    // Timeline animations
    tl.fromTo(
      ".main>h1",
      { opacity: 0, y: "100px" },
      { opacity: 0.5, y: "0", ease: "slow(0.7, 0.7, false)" }
    )
      .fromTo(
        ".fullname",
        { scale: 0, opacity: 0 },
        { opacity: 1, ease: "back", duration: 0.5, scale: 1 },
        ">-.2"
      )
      .fromTo(
        ".portfolio",
        { display: "none", opacity: 0, x: "150" },
        {
          display: "block",
          x: "0",
          opacity: 1,
          ease: "slow(0.7, 0.7, false)",
          duration: 0.5,
        },
        ">"
      )

      .to(".fullname", { x: "-10", ease: "bounce" }, "<")
      .to(".portfolio", { text: "Portfolio", ease: "slow", duration: 1.5 }, ">")
      .to(".fullname", { x: "0", ease: "bounce" }, "<.5")
      .fromTo(
        "#demoh5",
        { text: "", x: "200", opacity: 0.3 },
        {
          text: "Designed and Developed with Figma, HTML, SCSS, React, Node and GSAP",
          x: "0",
          ease: "slow(0.7, 0.7, false)",
          duration: 0.5,
        },
        ">-.5"
      )
      .addLabel("flipped")
      .fromTo(
        ".flipped",
        { y: -500, x: "50%" },
        { autoAlpha: 1, y: -60, x: "700%", ease: "bounce", duration: 1 },">"
      )
      .to(
        ".flipped",
        { y: -150, x: "800%", rotation: "45", ease: "power4", duration: 0.8 },
        ">-.65"
      )
      .to(
        ".flipped",
        { y: -40, x: "950%", rotation: "90", ease: "bounce", duration: 0.8 },
        ">-.5"
      )

      .to(
        ".flipped",
        { y: 12, rotation: "335", ease: "bounce", duration: 0.8 },
        ">-.8"
      )
      .to(
        ".flipped",
        {
          rotation: "355",
          ease: "linear",
          repeat: 1,
          yoyo: true,
        },
        ">"
      );

    tl.to(
      ".flipped",
      {
        rotation: "270",
        repeat: 0,
        x: "+=5",
        transformOrigin: "top left",
        y: 125,
        ease: "linear",
      },
      "<.5"
    );
  }, [tl]);

  return (
    <div className="main">
      <h1>Welcome To</h1>

      <div
        id="demo"
        style={{ alignItems: width < 1700 ? "center" : "baseline" }}
      >
        <h1 className="fullname">Solomon Oni's</h1>{" "}
        <p className="flipped" style={{ display: width < 1700 ? "none" : "" }}>
          UX|Frontend
        </p>{" "}
        <p
          className="unflipped"
          style={{ display: width < 1700 ? "" : "none" }}
        >
          UX|Frontend
        </p>{" "}
        <h1 className="portfolio">lfoioPrto</h1>{" "}
      </div>
      <h5 id="demoh5">
        Designed and Developed with Figma, HTML, SCSS, React, Node and GSAP
      </h5>
    </div>
  );
};

export default RedirectComponent;
