import React, { useState, useEffect } from "react";
import Lists from "../services/apiService";
import "./svg.css";

import useWindowSize from "../services/windowSizeService";

function Svg(props) {
  const [className, setClassName] = useState("home_bg");
  const [lists, setLists] = useState([])

  const width = useWindowSize();

    useEffect(() => {
      const fetchLists = async () => {
        const data = await Lists();
        setLists(data);
      };

      fetchLists();
    }, []);

  useEffect(() => {
    if (props.present_number < lists.length) {
      setClassName("home_bg animate_svg");

      // Set a timeout to remove the animate_svg class after 0.6 seconds
      const timer = setTimeout(() => {
        setClassName("home_bg");
      }, 600);

      // Cleanup function to clear the timeout if the component unmounts or updates
      return () => clearTimeout(timer);
    }
  }, [props.present_number, lists.length]); // Dependency array


  const currentProject = lists[props.present_number];
  

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 97.8 59.7241"
      preserveAspectRatio="none"
      fill="none"
      style={{
        height:
          width <= 1060
            ? `calc(100vh - calc(var(--svg-margin) * 9))`
            : `calc(100vh - calc(var(--svg-margin) * 2))`,
      }}
      className={className}
      id="svgImg"
    >
      <defs>
        <pattern
          id="imgPattern"
          patternUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          {currentProject ? (
            <image
              href={`${currentProject.img}`}
              width="100%"
              height="100%"
              preserveAspectRatio="xMinYMax slice"
            />
          ) : (
            <image
              href="/images/css-pattern-by-magicpattern.png"
              width="100%"
              height="100%"
              preserveAspectRatio="xMinYMax slice"
            />
          )}

          <rect
            width="100%"
            height="100%"
            className="glass-effect"
            // filter="url(#blur)"
          />
        </pattern>
      </defs>
      {width >= 1060 ? (
        <path
          d={`M18.633 0C17.838 0 17.236 0.617 17.236 1.379V5.586C17.236 6.339 16.637 6.962 15.873 6.962H1.378C0.617 6.962 0 7.582 0 8.344V53.145C0 53.907 0.617 54.526 1.378 54.526H6.264C7.059 54.526 7.66 55.146 7.66 55.91V57.234C7.66 57.996 8.278 58.617 9.041 58.617H79.015C80.041 58.617 81.037 57.611 81.037 56.586V15.993C81.037 14.967 82.032 13.962 83.057 13.962H95.616C96.641 13.962 97.637 12.967 97.637 11.942V1.379C97.637 0.617 97.036 0 96.273 0H18.633Z
`}
          fill="url('#imgPattern')"
        />
      ) : (
        <rect
          rx={6}
          ry={2}
          width="100%"
          height="100%"
          fill="url('#imgPattern')"
        />
      )}
    </svg>
  );
}

export default Svg;
