import React, { useEffect, useRef, useState } from "react";
import "./slider-manual.css";

function MSlider() {
  const sliderRef = useRef(null);
  const trackRef = useRef(null);

  // State to manage disabled status of buttons
  const [prevDisabled, setPrevDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(false);

  // Handle "Previous" button click
  const handlePrevClick = () => {
    const track = trackRef.current;
    if (track) {
      track.scrollTo({
        left: track.scrollLeft - track.firstElementChild.offsetWidth,
        behavior: "smooth",
      });
    }
  };

  // Handle "Next" button click
  const handleNextClick = () => {
    const track = trackRef.current;
    if (track) {
      track.scrollTo({
        left: track.scrollLeft + track.firstElementChild.offsetWidth,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const track = trackRef.current;

    const handleScroll = () => {
      const trackScrollWidth = track.scrollWidth;
      const trackOuterWidth = track.clientWidth;

      // Disable/Enable the prev and next buttons based on scroll position
      setPrevDisabled(track.scrollLeft <= 0);
      setNextDisabled(track.scrollLeft >= trackScrollWidth - trackOuterWidth);
    };

    // Attach scroll listener
    if (track) {
      track.addEventListener("scroll", handleScroll);
    }

    // Cleanup event listener on component unmount
    return () => {
      if (track) {
        track.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <div className="a-slider-container">
      <div className="a-slider" data-slider ref={sliderRef}>
        <div className="slider__buttons mb-3">
          <button
            className="button"
            data-slider-prev
            onClick={handlePrevClick}
            disabled={prevDisabled}
          >
            <div className="button-box">
              <span className="button-elem">
                <svg viewBox="0 0 46 40" xmlns="http://www.w3.org/2000/svg">
                  <path d="M46 20.038c0-.7-.3-1.5-.8-2.1l-16-17c-1.1-1-3.2-1.4-4.4-.3-1.2 1.1-1.2 3.3 0 4.4l11.3 11.9H3c-1.7 0-3 1.3-3 3s1.3 3 3 3h33.1l-11.3 11.9c-1 1-1.2 3.3 0 4.4 1.2 1.1 3.3.8 4.4-.3l16-17c.5-.5.8-1.1.8-1.9z"></path>
                </svg>
              </span>
              <span className="button-elem">
                <svg viewBox="0 0 46 40">
                  <path d="M46 20.038c0-.7-.3-1.5-.8-2.1l-16-17c-1.1-1-3.2-1.4-4.4-.3-1.2 1.1-1.2 3.3 0 4.4l11.3 11.9H3c-1.7 0-3 1.3-3 3s1.3 3 3 3h33.1l-11.3 11.9c-1 1-1.2 3.3 0 4.4 1.2 1.1 3.3.8 4.4-.3l16-17c.5-.5.8-1.1.8-1.9z"></path>
                </svg>
              </span>
            </div>
          </button>
          <button
            className="button"
            data-slider-next
            onClick={handleNextClick}
            disabled={nextDisabled}
          >
            <div className="button-box">
              <span className="button-elem">
                <svg viewBox="0 0 46 40" xmlns="http://www.w3.org/2000/svg">
                  <path d="M46 20.038c0-.7-.3-1.5-.8-2.1l-16-17c-1.1-1-3.2-1.4-4.4-.3-1.2 1.1-1.2 3.3 0 4.4l11.3 11.9H3c-1.7 0-3 1.3-3 3s1.3 3 3 3h33.1l-11.3 11.9c-1 1-1.2 3.3 0 4.4 1.2 1.1 3.3.8 4.4-.3l16-17c.5-.5.8-1.1.8-1.9z"></path>
                </svg>
              </span>
              <span className="button-elem">
                <svg viewBox="0 0 46 40">
                  <path d="M46 20.038c0-.7-.3-1.5-.8-2.1l-16-17c-1.1-1-3.2-1.4-4.4-.3-1.2 1.1-1.2 3.3 0 4.4l11.3 11.9H3c-1.7 0-3 1.3-3 3s1.3 3 3 3h33.1l-11.3 11.9c-1 1-1.2 3.3 0 4.4 1.2 1.1 3.3.8 4.4-.3l16-17c.5-.5.8-1.1.8-1.9z"></path>
                </svg>
              </span>
            </div>
          </button>
        </div>
        <ul className="slider__track" data-slider-track ref={trackRef}>
          <li>
            <div className="slide">
              <img
                src="./images/project/about-images/freetime/art/art.png"
                alt=""
              />
            </div>
            <div
              style={{
                padding: ".5rem",
                textWrap: "pretty",
                maxWidth: "65ch",
              }}
            >
              <p>Exploring Art and Creativity</p>
              <small>
                I'm captivated by the stories, emotions, and ideas behind art
                pieces.
              </small>
              <br />
              {/* <br /> */}
              <small>
                Inspired by what I see, I often try my hand at
                painting—experimenting and enjoying the creative process.
              </small>
            </div>
          </li>
          <li>
            <div className="slide">
              <img
                style={{ width: "100%", objectFit: "cover" }}
                src="./images/project/about-images/freetime/teach/mentoring.png"
                alt=""
              />
            </div>
            <div
              style={{
                padding: ".5rem",
                textWrap: "pretty",
                maxWidth: "65ch",
              }}
            >
              <p>Giving Back Through Mentorship</p>
              <small>
                Over the years, I've had the privilege of mentoring over 100
                individuals across platforms like ADPList, Behance, LinkedIn,
                and in-person sessions.
              </small>
              <br />
              <small>
                It's been a rewarding journey helping others grow and succeed in
                their careers.
              </small>
            </div>
          </li>
          <li>
            <div className="slide">
              <img
                src="./images/project/about-images/freetime/nature/nature.png"
                alt=""
              />
            </div>
            <div
              style={{
                padding: ".5rem",
                textWrap: "pretty",
                maxWidth: "65ch",
              }}
            >
              <p>Connecting with Nature</p>
              <small>
                I find peace and inspiration in nature. Exploring beautiful
                landscapes and the wonders of the earth helps me reset,
                recharge, and think beyond the usual boundaries.
              </small>
            </div>
          </li>

          <li>
            <div className="slide">
              <img
                src="./images/project/about-images/freetime/fun/fun.png"
                alt=""
              />
            </div>
            <div
              style={{
                padding: ".5rem",
                textWrap: "pretty",
                maxWidth: "65ch",
              }}
            >
              <p>Fun and Exploration</p>
              <small>
                I’m all about experiencing life through new perspectives—whether
                it’s diving into immersive exhibitions, or just having a good
                laugh with friends.
              </small>
              <br />
              <small>
                From optical illusions to street art, I’m always looking for
                something that challenges the norm and adds a bit of fun to
                life.
              </small>
            </div>
          </li>
          <li>
            <div className="slide">
              <img
                src="./images/project/about-images/freetime/philantrophy/philantrophy.png"
                alt=""
              />
            </div>
            <div
              style={{
                padding: ".5rem",
                textWrap: "pretty",
                maxWidth: "65ch",
              }}
            >
              <p>Giving Back to the Community</p>
              <small>
                I believe in giving back, whether through time, effort, or
                resources. One of the ways I do this is through philanthropic
                work, helping underprivileged communities in need.
              </small>
              <br />
              <small>
                Whether it’s hands-on support or organizing initiatives, I
                strive to make a difference wherever possible, ensuring that the
                spirit of community remains strong.
              </small>
            </div>
          </li>
          <li>
            <div className="slide">
              <img
                src="./images/project/about-images/freetime/photography/pics.png"
                alt=""
              />
            </div>
            <div
              style={{
                padding: ".5rem",
                textWrap: "pretty",
                maxWidth: "65ch",
              }}
            >
              <p>Capturing Culture</p>
              <small>
                I’m always drawn to capturing the beauty and vibrancy of my
                culture through photography. Every picture tells a story,
                preserving moments that reflect heritage and tradition.
              </small>
              <br />
              <small>
                Beyond that, I love exploring and experiencing the richness of
                other cultures around the world—each one offering a unique
                perspective and unforgettable memories.
              </small>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default MSlider;
