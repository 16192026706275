import React, { useState, useEffect } from "react";
import Lists from "../services/apiService";
import { Skeleton } from "@mui/material";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import useWindowSize from "../services/windowSizeService";
import Button from "./button";

function Content(props) {
  const [proList, setProLists] = useState([]);
  const width = useWindowSize();

  useEffect(() => {
    const fetchData = async () => {
      const data = await Lists();
      setProLists(data);
    };

    fetchData();
  }, []);

  const project = proList[props.currentNum] || {};

  if (!project || !project.name) {
    return (
      <div
        className="content"
        style={{
          bottom:
            width <= 1060 ? "clamp(40%, 10vw, 30%)" : "clamp(40%, 10vw, 30%)",
        }}
      >
        <Box sx={{ width: "100%", height: "100%" }}>
          <Skeleton
            width="100%"
            height={150}
            style={{
              backgroundColor: "rgba(220, 223, 249, .92)",
              marginBottom: -26,
            }}
          />
          <Skeleton
            width="100%"
            height={100}
            style={{
              backgroundColor: "rgba(220, 223, 249, .92)",
              marginBottom: -10,
            }}
          />
          <Skeleton
            width={70}
            height={50}
            style={{ backgroundColor: "rgba(220, 223, 249, .92)" }}
          />
        </Box>
      </div>
    );
  }

  return (
    <div
      className={`content ${props.animationActive ? "show" : ""}`}
      style={{
        bottom:
          width <= 1060 ? "clamp(32.6%, 10vw, 30%)" : "clamp(25.6%, 10vw, 30%)",
      }}
    >
      <h2 className="title">{project.name}</h2>
      <p className="description">
        {project.description.length > 150
          ? `${project.description.slice(0, 200)}...`
          : project.description}
      </p>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <Link to={`/project/${project.id}`}>
          <Button width={width} />
        </Link>

        {project.siteLink ? (
          <span>
            <Link
              to={`${project.siteLink}`}
              target="_blank"
              style={{ color: "#ffffff" }}
            >
              {width >= 800 ? "See Live Site" : "Live Site"}
            </Link>
          </span>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Content;
