import React from "react";
import "./contact-us.css";
import { Icon } from "@iconify/react";

function ContactUs() {
  return (
    <main className="contact-main text-center">
      <div className="container">
        <div className="row gy-md-4">
          <div className="col-12">
            <h2 className="statement">Drop a message or feedback</h2>
          </div>
          <div className="col-12">
            <p>
              Your presence here means a great deal to me. Thank you for your
              interest in my work. If you'd like to learn more, share feedback,
              or ask any questions, feel free to reach out—I'm just a click
              away!
            </p>
          </div>

          <div className="col-12 mb-md-5 mb-2">
            <div className="s-links gap-5">
              <a
                href="mailto:solomonooni@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                <Icon icon="line-md:email" />
              </a>
              <a
                href="https://www.linkedin.com/in/solomon-oni-38763112a/"
                target="_blank"
                rel="noreferrer"
              >
                <Icon icon="line-md:linkedin" />
              </a>
              <a
                href="https://github.com/SoloPayne"
                target="_blank"
                rel="noreferrer"
              >
                <Icon icon="line-md:github-loop" />
              </a>
              <a href="https://www.behance.net/payneoni" rel="noreferrer">
                <Icon icon="icon-park-outline:behance" />
              </a>
              <a
                href="./images/project/cv/Solomon_Olufemi_Oni_CV.pdf.pdf"
                download
              >
                <Icon icon="tabler:file-cv" />
              </a>
            </div>
          </div>

          <div className="col-12">
            <p>
              Feel free to reach out to me directly via{" "}
              <span>
                <a
                  href="https://wa.me/+447438694421"
                  target="_blank"
                  rel="noreferrer"
                >
                  WhatsApp
                </a>
              </span>{" "}
              or give me a{" "}
              <span>
                <a href="tel:+447438694421" target="_blank" rel="noreferrer">
                  call
                </a>
              </span>
              . I also offer mentorship through{" "}
              <span>
                <a
                  href="https://adplist.org/mentors/solomon-oni"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ADPList
                </a>{" "}
              </span>{" "}
              and I'm available for sessions.
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ContactUs;
