import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import RedirectComponent from "./components/cover-page";
import Home from "./components/home";
import Projects from "./components/projects";
import ContactUs from "./components/contact-us";
import Header from "./components/header";
import Footer from "./components/footer";
import About from "./components/about";
import Multipurpose from "./components/multipurpose-kit";
import ThemeSwitchService from "./services/themeSwitchService";
import gsap from "gsap";
import "animate.css";

import "./App.css";

function App() {
  const [theme, setTheme] = useState("");
  const [darkModeOn, setDarkModeOn] = useState(false);
  const location = useLocation().pathname;

  const pageLoaded = useEffect(() => {
    const themeSwitcher = document.getElementById("theme-switcher");

    function isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }

    if (!isSafari()) {
      // Add the 'animate-zoom' class for non-Safari browsers
      const imgElement = document.querySelector(".home-inner img");
      if (imgElement) {
        imgElement.classList.add("animate-zoom");
      }
    }

    if (themeSwitcher) {
      loadThemePreference(themeSwitcher);
      themeSwitcher.addEventListener("change", handleThemeToggle);
    }

    return () => {
      if (themeSwitcher) {
        themeSwitcher.removeEventListener("change", handleThemeToggle);
      }
    };
  }, []);

  window.addEventListener("load", pageLoaded);

  const loadThemePreference = (themeSwitcher) => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme === "dark") {
      document.body.classList.add("dark");
      themeSwitcher.checked = true;
      setTheme("dark");
      setDarkModeOn(true);
    } else if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      document.body.classList.add("dark");
      themeSwitcher.checked = true;
      setTheme("dark");
      setDarkModeOn(true);
    } else {
      themeSwitcher.checked = false;
      setTheme("");
      setDarkModeOn(false);
    }
  };

  const handleThemeToggle = () => {
    document.body.classList.toggle("dark");
    const newTheme = document.body.classList.contains("dark")
      ? "dark"
      : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
    setDarkModeOn(newTheme === "dark");
    ThemeSwitchService.darkModeOn(newTheme === "dark");
  };

  useEffect(() => {
    gsap.to(".down-arrow", {opacity: 0, 
      scrollTrigger: {
        trigger: ".down-arrow",
        start: "top center",
        end: "+=200",
        scrub: true,
      }
    })
  })

  return (
    <div>
      {location !== "/cover" && (
        <Header theme={theme} darkModeOn={darkModeOn} />
      )}

      <Routes>
        <Route path="/" element={<Navigate to="/cover" />} />
        <Route path="/cover" element={<RedirectComponent />} />
        <Route path="/home" element={<Home />} />
        <Route path="/project/:id" element={<Projects />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<ContactUs />} />
      </Routes>

      {location !== "/cover" && <Multipurpose darkModeOn={handleThemeToggle} />}
      {location === "/home" && <Footer />}
    </div>
  );
}

export default App;
