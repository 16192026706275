import React, { useState } from "react";
import "./sliding-box.css";

export default function SlidingBox(props) {

const researchAnalysis = props.researchAnalysis;

console.log(researchAnalysis);

  // State to track the active box
  const [activeBox, setActiveBox] = useState(1);

  // Function to handle click and set active box
  const handleBoxClick = (index) => {
    setActiveBox(index);
  };

  const boxes = [
    {
      id: 1,
      title: researchAnalysis.analysisQuestion1,
    },
    {
      id: 2,
      title: researchAnalysis.analysisQuestion2,
    },
    {
      id: 3,
      title: researchAnalysis.analysisQuestion3,
    },
  ];

  return (
    <div className="sliding-box">
      {boxes.map((box, index) => (
        <div
          key={index}
          className={`box ${activeBox === index + 1 ? "active" : ""}`}
          onClick={() => handleBoxClick(index)}
        >
          <h2>{box.title}</h2>
          <i>{index += 1}</i>
        </div>
      ))}
    </div>
  );
}
